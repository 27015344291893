<script setup>
import { useNuxtApp } from 'nuxt/app';
import { computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const { $socketError } = useNuxtApp()

const interfaceBlocker = computed(() => store.state.common.interfaceBlocker)
const economicsCaseFixed = computed(() => store.state.economics.options.fixed.isActive)
const unitCaseFixed = computed(() => store.state.module1.options.fixed.case)
const currentTab = computed(() => store.state.economics.options.tabs.find(tab => tab.isActive)?.id)
const caseId = computed(() => route.params.case_id)

let jobs = ref([])
let mainJob = ref(null)
let typeItem = ref(null)
let calcIsEnded = ref(false)

const clearCalculation = async () => {
	await $fetch(`/api/jobs/cancel_calc_item?item_type=${typeItem.value}&item_id=${route.params.case_id}`)

	closeModal()
}

const listenQueue = () => {
	const channel = Echo.channel(`item_${route.params.layer_id ? 'layer' : route.params.object_id ? 'object' : 'economics'}_case_${route.params.case_id}`)
	
	channel.listen('.item.change', async (e) => {

		console.log('e', e)

		// if (economicsCaseFixed.value || unitCaseFixed) return

		if (e.type_event === 'save') return

		if (e.type_event === 'init_calc' || e.type_event === 'init_analytics_calc') {
			jobs.value = []
			mainJob.value = null
			typeItem.value = null
			
			return
		}

		if (e.type_event === 'start_calc' || e.type_event === 'start_analytics_calc') {
			store.commit('common/blockInterface', {
				title: `ID${route.params.case_id}`,
				isActive: true,
				showQueue: true
			})
			mainJob.value = e.data.job.id
			typeItem.value = e.item.type_item

			return
		}

		if (e.type_event === 'end_calc' || e.type_event === 'end_analytics_calc') {
			mainJob.value = null
			typeItem.value = null
			calcIsEnded.value = true

			if (e.data.job.status === 3) {
				const errorMessage = e.data.job.metadata.error

				store.commit('common/setErrorData', {
					statusCode: e.data.job.status,
					message: errorMessage,
					showError: true
				})

				store.commit('common/blockInterface', {
					isActive: false
				})

				$socketError(errorMessage, new Error())

				return
			}

			const jobClass = e.item.class_basename
			let storeId = null

			if (jobClass === 'EconomicsCase') {
				storeId = 'economics'
			} else if (jobClass === 'LayerCase') {
				storeId = 'module1'
			} else if (jobClass === 'ObjectCase') {
				storeId = 'prospects'
			}

			if (storeId) {
				const { data, pending, error, refresh } = await useFetch(`/api/jobs/${e.data.job.id}`)

				if (error.value) {
					const errorMessage = error.value.data

					store.commit('common/setErrorData', {
						statusCode: error.value.statusCode,
						message: errorMessage.message,
						showError: true
					})

					store.commit('common/blockInterface', {
						isActive: false
					})

					$socketError(errorMessage.message, new Error())

					return
				}

				if (!data.value.result || !Object.keys(data.value.result).length) return

				if (e.type_event === 'end_calc') {
					store.commit(`${storeId}/saveResult`, {
						result: data.value.result
					})
				}

				if (e.type_event === 'end_analytics_calc') {
					store.commit(`${storeId}/saveAnalyticsResult`, {
						result: data.value.result
					})
				}

				if (storeId === 'module1') {
					const caseSections = ['props', 'cos']

					caseSections.forEach(type => {
						store.commit('module1/updateResultRelevance', {
							type: type,
							relevance: true
						})
					})
				}

				if (storeId === 'prospects') {
					store.commit('module1/updateResultRelevance', true)
				}
			}

			setTimeout(() => {
				store.commit('common/blockInterface', {
					isActive: false
				})
			}, 1000)

			return
		}

		if (e.type_event === 'progress_calc' || e.type_event === 'progress_analytics_calc') {
			store.commit('common/blockInterface', {
				progress: e.data.job.progress
			})
			return
		}

		const isJobInList = jobs.value.find(elem => elem.job.id === e.data.job.id)

		if (!isJobInList) {
			jobs.value.push(e.data)
		}

		if (e.data.job.status !== 0) {
			const index = jobs.value.findIndex(data => data.job.id === e.data.job.id)
			jobs.value[index] = e.data
		}

		// if (e.data.job.status === 2) {
		//     setTimeout(() => {
		//         const index = jobs.value.findIndex(data => data.job.id === e.data.job.id)
		//         jobs.value.splice(index, 1)
		//     }, 1500)
		// }
	})
}

const closeModal = () => {
	store.commit('common/blockInterface', {
		isActive: false
	})
}

onMounted(() => {
	listenQueue()
})

watch(interfaceBlocker, (blocker) => {
	if (blocker.isActive === false) {
		store.commit('common/blockInterface', {
			progress: 0,
			title: null,
			showQueue: false
		})

		jobs.value = []
		mainJob.value = null
		typeItem.value = null
		calcIsEnded.value = false
	}
}, { deep: true })

watch(caseId, (id) => {
	listenQueue()
})
</script>

<template>
	<div>
		<div class="blocker" :class="{ active: interfaceBlocker.isActive }">
			<div class="blocker__window">
				<div class="blocker__head">
					<div class="blocker__title">
						<div class="blocker__title--grey">{{ $t('queue.title') }}</div>
						{{ interfaceBlocker.title }}
					</div>
					<div class="blocker__clear" v-if="mainJob && typeItem" @click="clearCalculation()">{{ $t('queue.cancel')
					}}</div>
					<div class="blocker__close" v-if="calcIsEnded" @click="closeModal()">
						<span></span><span></span>
					</div>
				</div>
				<div class="blocker__progress">{{ $t('queue.processed') }} {{ interfaceBlocker.progress }}%</div>
				<div class="blocker__loader">
					<div class="blocker__loader--inner" :style="{ 'max-width': `${interfaceBlocker.progress}%` }"></div>
				</div>
				<div class="blocker__jobs" v-if="interfaceBlocker.showQueue">
					<div class="queue">
						<div class="queue__modal">
							<div class="queue-modal">
								<div class="queue-modal__head">
									<div class="queue-modal__title">{{ $t('queue.tasks_in_progress') }}</div>
								</div>
								<div class="queue-modal__content">
									<div class="queue-modal__item" v-for="(data, index) in jobs" :key="index">
										<div class="queue-modal__name">{{ data.job.name }}</div>
										<div class="queue-modal__text">
											<template v-if="data.job.status === 0">{{ $t('queue.status_pending')
											}}</template>
											<template v-if="data.job.status === 1">{{ $t('queue.status_processed') }} {{
												data.job.progress }}%</template>
											<template v-if="data.job.status === 2">{{ $t('queue.status_completed')
											}}</template>
											<template v-if="data.job.status === 3">{{ $t('queue.status_error') }}</template>
											<template v-if="data.job.status === 4">{{ $t('queue.status_paused')
											}}</template>
										</div>
										<div class="queue-modal__status" :class="`queue-modal__status--${data.job.status}`">
											{{ data.job.status_text }}
										</div>
										<div class="queue-modal__icon" :class="{ hidden: data.job.status !== 3 }" @click="
											store.commit('common/setErrorData', {
												statusCode: data.job.status,
												message: data.job.metadata.error || $t('queue.unknown_error'),
												showError: true
											})
											">
											<svg width="16rem" height="16rem" viewBox="0 0 16 16" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path opacity="0.5"
													d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM8.93 6.588L7.93 11.293C7.86 11.633 7.959 11.826 8.234 11.826C8.428 11.826 8.721 11.756 8.92 11.58L8.832 11.996C8.545 12.342 7.912 12.594 7.367 12.594C6.664 12.594 6.365 12.172 6.559 11.275L7.297 7.807C7.361 7.514 7.303 7.408 7.01 7.337L6.559 7.256L6.641 6.875L8.931 6.588H8.93ZM8 5.5C7.73478 5.5 7.48043 5.39464 7.29289 5.20711C7.10536 5.01957 7 4.76522 7 4.5C7 4.23478 7.10536 3.98043 7.29289 3.79289C7.48043 3.60536 7.73478 3.5 8 3.5C8.26522 3.5 8.51957 3.60536 8.70711 3.79289C8.89464 3.98043 9 4.23478 9 4.5C9 4.76522 8.89464 5.01957 8.70711 5.20711C8.51957 5.39464 8.26522 5.5 8 5.5Z"
													fill="#DD303A" />
											</svg>
										</div>
									</div>
									<div class="queue-modal__empty" v-if="!jobs.length">{{ $t('queue.empty') }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.blocker {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgba(256, 256, 256, 0.8);
	z-index: -1;

	&.active {
		z-index: 5;
	}

	&__window {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		background: white;
		width: 650rem;
		height: fit-content;
		box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.12);
		padding: 20rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__head {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 28rem;
		margin-bottom: 10rem;
		padding-right: 25rem;
	}

	&__title {
		font-size: 16rem;
		line-height: 26rem;
		color: var(--text-color);
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&--grey {
			color: var(--text-grey-color);
			margin-right: 10rem;
		}
	}

	&__clear {
		height: 28rem;
		border-radius: 20rem;
		padding: 0 12rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--border-grey-color);
		color: var(--text-color);
		font-size: 12rem;
		cursor: pointer;
	}

	&__close {
		width: 20rem;
		height: 20rem;
		position: absolute;
		right: 20rem;
		top: 20rem;
		border-radius: 50%;
		background: var(--border-grey-color);
		transition: all 0.2s ease;
		transform: rotate(45deg);
		cursor: pointer;

		&:hover {
			transform: rotate(0);
		}

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			height: 1rem;
			width: 11rem;
			background: var(--text-color);
			border-radius: 2rem;

			&:nth-child(2) {
				transform: translateX(-50%) translateY(-50%) rotate(90deg);
			}
		}
	}

	&__progress {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--text-grey-color);
		font-size: 12rem;
		line-height: 16rem;
		margin-bottom: 10rem;
		padding-right: 16rem;
	}

	&__loader {
		width: calc(100% - 16rem);
		margin-right: auto;
		height: 4rem;
		border-radius: 3rem;
		background: var(--grey-color);
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&--inner {
			height: 100%;
			width: 100%;
			background: var(--primary-color);
			transition: all 0.3s ease;
		}
	}

	&__jobs {
		width: 100%;
	}
}

.queue {
	&__modal {
		position: relative;
		transition: all 0.3s ease;

		.queue-modal {
			background: white;
			overflow: hidden;
			width: 100%;

			&__head {
				height: 40rem;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 0 20rem;
				margin-bottom: 10rem;
				margin-top: 10rem;
			}

			&__title {
				font-size: 14rem;
				color: var(--text-color);
			}

			&__content {
				height: 350rem;
				overflow-y: scroll;
			}

			&__item {
				height: fit-content;
				min-height: 40rem;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 8rem 10rem 8rem 20rem;
				font-size: 12rem;
				color: var(--text-color);
				margin-bottom: 10rem;
				transition: all 0.2s ease;

				&:hover {
					background: var(--grey-color);
				}

				.color {
					color: var(--primary-color);
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			&__name {
				width: 230rem;
				font-size: 12rem;
				line-height: 16rem;
				color: var(--text-color);
				margin-right: 10rem;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			&__text {
				width: 180rem;
				margin-right: auto;
				color: var(--text-grey-color);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}

			&__status {
				margin-right: 10rem;
				height: 24rem;
				padding: 0 10rem;
				border-radius: 19rem;
				font-size: 10rem;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: center;

				&--0,
				&--4 {
					background: var(--grey-color);
					color: var(--text-grey-color);
				}

				&--1 {
					background: rgba(0, 176, 49, 0.10);
					color: #00B031;
				}

				&--2 {
					background: rgba(17, 126, 194, 0.10);
					color: #117EC2;
				}

				&--3 {
					background: rgba(221, 48, 58, 0.10);
					color: #DD303A;
				}
			}

			&__icon {
				margin-right: 20rem;
				width: 16rem;
				height: 16rem;
				cursor: pointer;

				&.hidden {
					opacity: 0;
				}
			}

			&__empty {
				color: var(--text-grey-color);
				font-size: 12rem;
				height: 45rem;
				padding: 0 20rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}</style>